import React from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonAvatar, Typography } from '@creditas-ui/react'
import {
  LazyLoadImage,
  trackWindowScroll,
} from 'react-lazy-load-image-component'
import WomanImage from 'images/common/footer_user.webp'
import { addLinkParams } from 'utils/formatQueryParams'
import {
  About,
  AddressContainer,
  Benefits,
  CompanyInfo,
  Contact,
  Loans,
  MenuContainer,
  MenuItemsContainer,
  MenuLink,
  Une,
  UneLabel,
  WImage,
  Wrapper,
} from './Desktop.styles'

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line react/prop-types
const MenuDesktop = ({ scrollPosition }) => {
  const { t } = useTranslation()
  const loans = t('footer:navigation:loans', { returnObjects: true })
  const contact = t('footer:navigation:contact', { returnObjects: true })
  const benefits = t('footer:navigation:benefits', { returnObjects: true })
  const about = t('footer:navigation:about', { returnObjects: true })
  const companyInfo = t('footer:navigation:companyInfo', {
    returnObjects: true,
  })
  const une = t('footer:navigation:une', { returnObjects: true })

  return (
    <Wrapper>
      <MenuContainer>
        <MenuItemsContainer>
          <Loans>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodyMdBold"
            >
              {loans.label}
            </Typography>
            {loans.links.map(link => (
              <MenuLink
                key={link.href}
                rel="noreferrer"
                href={link.href}
                onClick={addLinkParams}
                target="_self"
              >
                {link.text}
              </MenuLink>
            ))}
          </Loans>
          <Benefits>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodyMdBold"
            >
              {benefits.label}
            </Typography>
            {benefits.links.map(link => (
              <MenuLink
                key={link.href}
                rel="noreferrer"
                target="_self"
                href={link.href}
                onClick={addLinkParams}
              >
                {link.text}
              </MenuLink>
            ))}
          </Benefits>
          <About>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodyMdBold"
            >
              {about.label}
            </Typography>
            {about.links.map(link => (
              <MenuLink
                key={link.href}
                href={link.href}
                rel="noreferrer"
                target="_blank"
                onClick={addLinkParams}
              >
                {link.text}
              </MenuLink>
            ))}
          </About>
          <Contact>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodyMdBold"
            >
              {contact.label}
            </Typography>
            {contact.links.map(link => (
              <MenuLink
                key={link.href}
                href={link.href}
                rel="noreferrer"
                target="_blank"
              >
                {link.text}
              </MenuLink>
            ))}
          </Contact>
          <CompanyInfo>
            <Typography
              color="neutral.90"
              component="span"
              variant="bodyMdBold"
            >
              {companyInfo.label}
            </Typography>
            {companyInfo.links.map(link => (
              <AddressContainer key={link.text}>
                <Typography
                  color="neutral.60"
                  component="span"
                  variant="bodyMdMedium"
                >
                  {link.text}
                </Typography>
              </AddressContainer>
            ))}
          </CompanyInfo>
          <Une>
            <UneLabel>
              <Typography
                color="neutral.90"
                component="span"
                variant="bodyMdBold"
              >
                {une.label}
              </Typography>
            </UneLabel>
            {une.links.map(link => (
              <MenuLink
                key={link.href}
                href={link.href}
                rel="noreferrer"
                target="_blank"
              >
                {link.text}
              </MenuLink>
            ))}
          </Une>
        </MenuItemsContainer>
        <WImage>
          <LazyLoadImage
            src={WomanImage}
            alt="persona"
            className="img-lazy-loading"
            placeholder={
              <SkeletonAvatar color="neutral.80" width={240} height={240} />
            }
            threshold={0}
            delayMethod="throttle"
            delayTime={300}
            scrollPosition={scrollPosition}
          />
        </WImage>
      </MenuContainer>
    </Wrapper>
  )
}
/* eslint-disable max-lines-per-function */

export default trackWindowScroll(MenuDesktop)
